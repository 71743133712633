<template>
  <v-dialog v-model="addDialog" persistent max-width="700">
    <v-card>
      <v-card-title>add more information</v-card-title>
      <v-card-text>
        <v-text-field
          label="specific medical condition"
          v-model="create.medics_request"
          hint="describe your condition"
          persistent-hint
        ></v-text-field>

        <v-text-field
          label="routine drugs"
          v-model="create.routine_drugs"
          hint="Medications you normally take when you have these condition"
          persistent-hint
        ></v-text-field>

        <v-text-field
          label="allergies"
          v-model="create.allergies"
          hint="Allergies associated with this medical conditions"
          persistent-hint
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.create.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Util from "../../../mixins/utils";
export default {
  mixins: [Util, validationMixin],
  props: { addDialog: Boolean, myId: { type: String } },
  data: () => ({
    startmenu: false,
    endmenu: false,
    meetypesList: [],
    create: {
      medics_request: null,
      allergies: null,
      routine_drugs: null,
    },
  }),
  validations: {
    create: {
      medics_request: {
        required,
      },
      allergies: {
        required,
      },
      routine_drugs: {
        required,
      },
    },
  },
  created() {},
  methods: {
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeAdd", payload);
    },
    submit() {
      let id = parseInt(this._decode(this.myId));
      let medics_request = this.create.medics_request;
      let allergies = this.create.allergies;
      let routine_drugs = this.create.routine_drugs;
      let data = {
        id,
        medics_request,
        allergies,
        routine_drugs,
      };
      console.log(data);
      let payload = { state: false, data: data };
      this.$emit("submitAdd", payload);
    },
  },
};
</script>
