<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          class="capitalize"
          style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn color="primary" text x-small @click.stop="addDialog = true">
          new information
        </v-btn>
        <MoreInforNew
          :addDialog="addDialog"
          :myId="id"
          @closeAdd="closeAdd"
          @submitAdd="submitAdd"
        />
        <!-- of more medical -->
        <v-btn color="primary" icon @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon @click="goBack" v-on="on">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
          </template>
          <span>Go Back</span>
        </v-tooltip>
      </v-toolbar>

      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-toolbar dense flat style="margin-bottom: 7px">
        <v-toolbar-title>
          {{ myDate | myDate }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        {{ _decode(start) | myDateTiming }}
        <span style="text-transform: lowercase; padding: 0px 7px">to</span>
        {{ _decode(end) | myDateTiming }}
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="moreList"
        :items-per-page="10"
        :loading="loading"
        loader-height="1"
      >
        <template v-slot:item.id="{ item }">
          <v-btn
            color="primary"
            text
            x-small
            @click.stop="$set(viewDialog, item.id, true)"
          >
            view
          </v-btn>
          <MoreInfoView
            v-if="viewDialog[item.id]"
            :myId="item.id"
            :viewDialog="viewDialog[item.id]"
            @closeView="closeView"
          />
          <v-btn
            color="success"
            text
            x-small
            @click.stop="$set(editDialog, item.id, true)"
          >
            edit
          </v-btn>
          <MoreInfoEdit
            v-if="editDialog[item.id]"
            :editDialog="editDialog[item.id]"
            :myId="item.id"
            @closeEdit="closeEdit"
            @submitEdit="submitEdit"
          />
          <v-btn
            color="error"
            text
            x-small
            @click.stop="$set(removeDialog, item.id, true)"
          >
            remove
          </v-btn>
          <MoreInfoRemove
            v-if="removeDialog[item.id]"
            :myId="item.id"
            :removeDialog="removeDialog[item.id]"
            @closeRemove="closeRemove"
            @submitRemove="submitRemove"
          />
        </template>
      </v-data-table>
    </div>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Util from "../../mixins/utils";
import WinScroll from "../../mixins/windowscroll";
import MoreInforNew from "../../components/appointments/moreinfo/newone.vue";
import MoreInfoView from "../../components/appointments/moreinfo/view.vue";
import MoreInfoEdit from "../../components/appointments/moreinfo/update.vue";
import MoreInfoRemove from "../../components/appointments/moreinfo/remove.vue";

export default {
  props: {
    id: { type: String },
    date: { type: String },
    loc: { type: String },
    start: { type: String },
    end: { type: String },
  },
  mixins: [Util, WinScroll("position")],
  components: { MoreInforNew, MoreInfoView, MoreInfoEdit, MoreInfoRemove },
  data: () => ({
    addDialog: false,
    viewDialog: {},
    editDialog: {},
    removeDialog: {},
    crumbs: [
      {
        exact: true,
        link: true,
        text: "Dashboard",
        to: { name: "patient.dash" },
      },
      {
        exact: true,
        link: true,
        text: "Appointment",
        to: { name: "patient.appointments" },
      },
      {
        exact: true,
        link: true,
        text: "More Information",
        to: { name: "patient.appointments.more" },
      },
    ],
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    loading: false,
    moreList: [],
    headers: [
      { text: "condition", value: "medics_request" },
      { text: "routine drugs", value: "routine_drugs" },
      { text: "allergies", value: "allergies" },
      // { text: "active", value: "active", align: "center" },
      { text: "actions", value: "id", align: "center" },
    ],
  }),
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
    myDate: function () {
      let date = this._decode(this.date);
      return date;
    },
  },
  created() {
    this.detail();
    this.list();
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    list() {
      this.loading = true;
      let self = this;
      Restful.medics.patientMoreInfo
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.moreList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    detail() {
      let date = this._decode(this.date);
      console.log(date);
    },
    refresh() {},
    menulink() {
      this.$emit("sidebar");
    },
    closeAdd(p) {
      this.addDialog = p.state;
    },
    submitAdd(p) {
      this.snack.bar = true;
      console.log(p);
      Restful.medics.patientMoreInfo
        .create(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.addDialog = p.state;
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      this.snack.bar = true;
      console.log(p);
      Restful.medics.patientMoreInfo
        .update(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      console.log(p);
      Restful.medics.patientMoreInfo
        .delete(p.id)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "error";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
  },
};
</script>
