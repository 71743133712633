<template>
  <v-dialog v-model="editDialog" persistent max-width="700">
    <v-card>
      <v-card-title>add more information</v-card-title>
      <v-card-text>
        <v-text-field
          label="specific medical condition"
          v-model="create.medics_request"
          hint="describe your condition"
          persistent-hint
        ></v-text-field>

        <v-text-field
          label="routine drugs"
          v-model="create.routine_drugs"
          hint="Medications you normally take when you have these condition"
          persistent-hint
        ></v-text-field>

        <v-text-field
          label="allergies"
          v-model="create.allergies"
          hint="Allergies associated with this medical conditions"
          persistent-hint
        ></v-text-field>

        <v-switch
          v-model="create.active"
          :label="`Active: ${create.active.toString()}`"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.create.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Util from "../../../mixins/utils";
export default {
  mixins: [Util, validationMixin],
  props: { editDialog: Boolean, myId: { type: Number } },
  data: () => ({
    startmenu: false,
    endmenu: false,
    meetypesList: [],
    create: {
      medics_request: null,
      allergies: null,
      routine_drugs: null,
      active: false,
    },
  }),
  validations: {
    create: {
      medics_request: {
        required,
      },
      allergies: {
        required,
      },
      routine_drugs: {
        required,
      },
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.medics.patientMoreInfo
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          this.create.medics_request = response.data.medics_request;
          this.create.allergies = response.data.allergies;
          this.create.routine_drugs = response.data.routine_drugs;
          this.create.active = response.data.active;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeEdit", payload);
    },
    submit() {
      let medics_request = this.create.medics_request;
      let allergies = this.create.allergies;
      let routine_drugs = this.create.routine_drugs;
      let active = this.create.active;
      let data = {
        medics_request,
        allergies,
        routine_drugs,
        active,
      };
      console.log(data);
      let payload = { id: this.myId, state: false, data: data };
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
